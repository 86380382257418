
































































































import {
  computed,
  defineComponent,
  ref, useFetch, useRoute, watch,
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';

import SwitchAndSaveBanner from '~/components/SwitchAndSave/components/Banner/Banner.vue';
import { useStructuredData } from '~/composables';
import { getHomeBestsellers } from '~/composables/useHome';
import { HOME_BLOCK_IDS } from '~/constants';
import { getFullUrl } from '~/helpers/urlHelpers';
import ManufacturerPreloaded from '~/modules/catalog/category/components/views/ManufacturerPreloaded/ManufacturerPreloaded.vue';
import SearchWidget from '~/modules/catalog/category/components/views/SearchWidget.vue';
import { useCmsBlocksStore } from '~/stores/cmsBlocks';

import HomeBestsellers from '../components/Home/Bestsellers/Bestsellers.vue';
import HomeAdvantages from '../components/Home/HomeAdvantages/HomeAdvantages.vue';
import HomeTestimonials from '../components/Home/HomeTestimonials.vue';
import HomePopularPrinters from '../components/Home/PopularPrinters.vue';
import PromoAbout from '../components/Home/PromoAbout.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    LazyHydrate,
    SwitchAndSaveBanner,
    PromoAbout,
    SearchWidget,
    HomeAdvantages,
    HomeBestsellers,
    HomePopularPrinters,
    HomeTestimonials,
    ManufacturerPreloaded,
  },
  setup() {
    const { collectOrganizationJsonLd } = useStructuredData();
    const { load, getCmsBlockByIdentifier } = useCmsBlocksStore();
    const route = useRoute();

    const jsonLd = computed(() => collectOrganizationJsonLd());
    const bestsellers = ref({ ink: [], toner: [] });

    watch(route, () => {
      if (!getCmsBlockByIdentifier(HOME_BLOCK_IDS[0])) {
        load(HOME_BLOCK_IDS, true);
      }
    }, { immediate: true });

    useFetch(async () => {
      bestsellers.value = await getHomeBestsellers();
    });

    return {
      jsonLd,
      bestsellers,
    };
  },
  head() {
    return {
      link: [
        { rel: 'canonical', href: getFullUrl('/') },
      ],
      meta: [
        {
          property: 'og:image',
          content: 'https://www.cartridgesave.co.uk/media/cartridge-save-logo.png',
          vmid: 'og:image',
        },
      ],
    };
  },
});
