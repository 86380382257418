





















































import {
  defineComponent, nextTick,
  onMounted, ref,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { useSearchInput } from '~/composables';

export default defineComponent({
  name: 'SearchWidget',
  components: {
    SvgImage,
  },
  props: {
    isHomePage: {
      type: Boolean,
    },
  },
  setup() {
    const { searchInput, doSearch } = useSearchInput();
    const search = ref(null);

    onMounted(() => {
      nextTick(() => {
        search.value?.focus();
      });
    });

    return {
      searchInput,
      search,
      doSearch,
    };
  },
});
