







































import { defineComponent } from '@nuxtjs/composition-api';

import BestsellerItem from './BestsellerItem.vue';

export default defineComponent({
  name: 'HomeBestsellers',
  components: {
    BestsellerItem,
  },
  props: {
    collection: {
      type: Array,
      default: () => [],
    },
    collectionTitle: {
      type: String,
      default: '',
    },
  },
});
