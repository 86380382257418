var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{class:_vm.$style.title},[_vm._v("\n    "+_vm._s(_vm.title)+"\n  ")]),_vm._v(" "),_c('div',{class:_vm.$style.listWrapper},[_c('ul',{class:( _obj = {}, _obj[_vm.$style.list] = true, _obj )},_vm._l((_vm.items),function(ref,index){
      var _obj;

      var src = ref.src;
      var name = ref.name;
      var to = ref.to;
      var inkTypes = ref.inkTypes;return _c('li',{key:to,class:( _obj = {}, _obj[_vm.$style.item] = true, _obj['hidden'] =  (index > _vm.VISIBLE_ITEMS - 1) && !_vm.showAll, _obj )},[(!inkTypes)?_c('ManufacturerLink',{attrs:{"to":to,"src":src,"alt":(name + " " + _vm.manufacturerType + " cartridges")}}):_c('ManufacturerHomeLink',{attrs:{"src":src,"alt":(name + " ink cartridges"),"name":name,"ink-types":inkTypes}})],1)}),0)]),_vm._v(" "),_c('div',{class:_vm.$style.moreInfo},[_c('button',{class:_vm.$style.button,attrs:{"type":"button"},on:{"click":function($event){_vm.showAll = !_vm.showAll}}},[_c('SvgImage',{class:( _obj$1 = {}, _obj$1[_vm.$style.icon] = true, _obj$1[_vm.$style.iconUp] = _vm.showAll, _obj$1 ),attrs:{"icon":"circle-chevron-down","width":"14","height":"14"}}),_vm._v(_vm._s(_vm.$t('View {value} printer brands', { value: !_vm.showAll ? 'all' : 'fewer' }))+"\n    ")],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }