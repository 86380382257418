export const MANUFACTURERS = {
  ink: [
    {
      to: '/ink-cartridges/Brother.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/brother/{size}/brother.png',
      name: 'Brother',
    },
    {
      to: '/ink-cartridges/Canon.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/canon/{size}/canon.png',
      name: 'Canon',
    },
    {
      to: '/ink-cartridges/Dell.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/dell/{size}/dell.png',
      name: 'Dell',
    },
    {
      to: '/ink-cartridges/Epson.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/epson/{size}/epson.png',
      name: 'Epson',
    },
    {
      to: '/ink-cartridges/HP.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/hp/{size}/hp.png',
      name: 'HP',
    },
    {
      to: '/ink-cartridges/Kodak.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/kodak/{size}/kodak.png',
      name: 'Kodak',
    },
    {
      to: '/ink-cartridges/Lexmark.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/lexmark/{size}/lexmark.png',
      name: 'Lexmark',
    },
    {
      to: '/ink-cartridges/Neopost.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/neopost/{size}/neopost.png',
      name: 'Neopost',
    },
    {
      to: '/ink-cartridges/Philips.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/philips/{size}/philips.png',
      name: 'Philips',
    },
    {
      to: '/ink-cartridges/Pitney-Bowes.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/pitney-bowes/{size}/pitney-bowes.png',
      name: 'Pitney Bowes',
    },
    {
      to: '/ink-cartridges/Ricoh.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/ricoh/{size}/ricoh.png',
      name: 'Ricoh',
    },
    {
      to: '/ink-cartridges/Xerox.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/xerox/{size}/xerox.png',
      name: 'Xerox',
    },
    {
      to: '/ink-cartridges/Citizen.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/citizen/{size}/citizen.png',
      name: 'Citizen',
    },
    {
      to: '/ink-cartridges/Fujitsu.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/fujitsu/{size}/fujitsu.png',
      name: 'Fujitsu',
    },
    {
      to: '/ink-cartridges/IBM.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/ibm/{size}/ibm.png',
      name: 'IBM',
    },
    {
      to: '/ink-cartridges/Oki.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/oki/{size}/oki.png',
      name: 'Oki',
    },
    {
      to: '/ink-cartridges/Olivetti.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/olivetti/{size}/olivetti.png',
      name: 'Olivetti',
    },
    {
      to: '/ink-cartridges/Panasonic.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/panasonic/{size}/panasonic.png',
      name: 'Panasonic',
    },
    {
      to: '/ink-cartridges/Quadient.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/quadient/{size}/quadient.png',
      name: 'Quadient',
    },
    {
      to: '/ink-cartridges/Samsung.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/samsung/{size}/samsung.png',
      name: 'Samsung',
    },
    {
      to: '/ink-cartridges/Sharp.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/sharp/{size}/sharp.png',
      name: 'Sharp',
    },
    {
      to: '/ink-cartridges/Star.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/star/{size}/star.png',
      name: 'Star',
    },
    {
      to: '/ink-cartridges/TallyGenicom.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/tallygenicom/{size}/tallygenicom.png',
      name: 'TallyGenicom',
    },
  ],
  toner: [
    {
      to: '/toner-cartridges/Brother.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/brother/{size}/brother.png',
      name: 'Brother',
    },
    {
      to: '/toner-cartridges/Canon.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/canon/{size}/canon.png',
      name: 'Canon',
    },
    {
      to: '/toner-cartridges/Dell.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/dell/{size}/dell.png',
      name: 'Dell',
    },
    {
      to: '/toner-cartridges/Epson.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/epson/{size}/epson.png',
      name: 'Epson',
    },
    {
      to: '/toner-cartridges/HP.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/hp/{size}/hp.png',
      name: 'HP',
    },
    {
      to: '/toner-cartridges/Konica-Minolta.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/konica-minolta/{size}/konica-minolta.png',
      name: 'Konica Minolta',
    },
    {
      to: '/toner-cartridges/Kyocera.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/kyocera/{size}/kyocera.png',
      name: 'Kyocera',
    },
    {
      to: '/toner-cartridges/Lexmark.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/lexmark/{size}/lexmark.png',
      name: 'Lexmark',
    },
    {
      to: '/toner-cartridges/Oki.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/oki/{size}/oki.png',
      name: 'Oki',
    },
    {
      to: '/toner-cartridges/Ricoh.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/ricoh/{size}/ricoh.png',
      name: 'Ricoh',
    },
    {
      to: '/toner-cartridges/Samsung.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/samsung/{size}/samsung.png',
      name: 'Samsung',
    },
    {
      to: '/toner-cartridges/Xerox.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/xerox/{size}/xerox.png',
      name: 'Xerox',
    },
    {
      to: '/toner-cartridges/IBM.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/ibm/{size}/ibm.png',
      name: 'IBM',
    },
    {
      to: '/toner-cartridges/Olivetti.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/olivetti/{size}/olivetti.png',
      name: 'Olivetti',
    },
    {
      to: '/toner-cartridges/Panasonic.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/panasonic/{size}/panasonic.png',
      name: 'Panasonic',
    },
    {
      to: '/toner-cartridges/Sharp.html',
      src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/sharp/{size}/sharp.png',
      name: 'Sharp',
    },
  ],
  home: [{
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/brother/{size}/brother.png',
    name: 'Brother',
    inkTypes: ['ink-cartridges', 'toner-cartridges', 'label-tape'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/canon/{size}/canon.png',
    name: 'Canon',
    inkTypes: ['ink-cartridges', 'toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/dell/{size}/dell.png',
    name: 'Dell',
    inkTypes: ['ink-cartridges', 'toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/epson/{size}/epson.png',
    name: 'Epson',
    inkTypes: ['ink-cartridges', 'toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/hp/{size}/hp.png',
    name: 'Hp',
    inkTypes: ['ink-cartridges', 'toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/konica-minolta/{size}/konica-minolta.png',
    name: 'Konica-minolta',
    inkTypes: ['toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/kyocera/{size}/kyocera.png',
    name: 'Kyocera',
    inkTypes: ['toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/lexmark/{size}/lexmark.png',
    name: 'Lexmark',
    inkTypes: ['ink-cartridges', 'toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/oki/{size}/oki.png',
    name: 'Oki',
    inkTypes: ['ink-cartridges', 'toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/ricoh/{size}/ricoh.png',
    name: 'Ricoh',
    inkTypes: ['ink-cartridges', 'toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/samsung/{size}/samsung.png',
    name: 'Samsung',
    inkTypes: ['ink-cartridges', 'toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/xerox/{size}/xerox.png',
    name: 'Xerox',
    inkTypes: ['ink-cartridges', 'toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/citizen/{size}/citizen.png',
    name: 'Citizen',
    inkTypes: ['ink-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/dymo/{size}/dymo.png',
    name: 'Dymo',
    inkTypes: ['label-tape'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/fujitsu/{size}/fujitsu.png',
    name: 'Fujitsu',
    inkTypes: ['ink-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/ibm/{size}/ibm.png',
    name: 'Ibm',
    inkTypes: ['ink-cartridges', 'toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/kodak/{size}/kodak.png',
    name: 'Kodak',
    inkTypes: ['ink-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/neopost/{size}/neopost.png',
    name: 'Neopost',
    inkTypes: ['ink-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/olivetti/{size}/olivetti.png',
    name: 'Olivetti',
    inkTypes: ['ink-cartridges', 'toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/panasonic/{size}/panasonic.png',
    name: 'Panasonic',
    inkTypes: ['ink-cartridges', 'toner-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/philips/{size}/philips.png',
    name: 'Philips',
    inkTypes: ['ink-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/pitney-bowes/{size}/pitney-bowes.png',
    name: 'Pitney-bowes',
    inkTypes: ['ink-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/quadient/{size}/quadient.png',
    name: 'Quadient',
    inkTypes: ['ink-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/sharp/{size}/sharp.png',
    name: 'Sharp',
    inkTypes: ['ink-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/star/{size}/star.png',
    name: 'Star',
    inkTypes: ['ink-cartridges'],
  }, {
    src: 'https://www.cartridgesave.co.uk/media/wysiwyg/thumbnail/brand/tallygenicom/{size}/tallygenicom.png',
    name: 'Tallygenicom',
    inkTypes: ['ink-cartridges'],
  }],
};

export const TYPE_LABELS = {
  'ink-cartridges': 'Ink',
  'toner-cartridges': 'Toner',
  'label-tape': 'Label tape',
};
