export default `
query getHomeBestsellers($inkSkus: [String], $tonerSkus: [String]) {
    ink: products(filter: { sku: { in: $inkSkus } } ) {
        items {
          id
          name
          sku
          specification_product_type
          url_key
          canonical_url
          thumbnail {
            url
          }
          price {
            regularPrice {
              adjustments {
                amount {
                  value
                }
              }
              amount {
                value
              }
            }
            minimalPrice {
              adjustments {
                amount {
                  value
                }
              }
              amount {
                value
              }
            }
          }
        },
        total_count
    },
    toner: products(filter: { sku: { in: $tonerSkus } } ) {
        items {
          id
          name
          sku
          specification_product_type
          url_key
          canonical_url
          thumbnail {
            url
          }
          price {
            regularPrice {
              adjustments {
                amount {
                  value
                }
              }
              amount {
                value
              }
            }
            minimalPrice {
              adjustments {
                amount {
                  value
                }
              }
              amount {
                value
              }
            }
          }
        },
        total_count
    }
}
`;
