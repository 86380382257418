import { useApi } from '~/composables/useApi';

import bestsellersGql from './bestsellers.gql';
import { BESTSELLER_SKUS } from './constants';
import { HomeReturnData } from './useHome';

export async function getHomeBestsellers() {
  const { query } = useApi();
  let result: HomeReturnData;

  try {
    result = await query(bestsellersGql, BESTSELLER_SKUS);
  } catch {
    result = null;
  }

  return {
    ink: result?.data?.ink.items,
    toner: result?.data?.toner.items,
  };
}
