



































































import { computed, useCssModule } from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';

export default {
  name: 'SwitchAndSaveBanner',
  components: { SvgImage },
  props: {
    propsStyles: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const defaultStyles = useCssModule();

    const computedStyles = computed(() => ({ ...defaultStyles, ...props.propsStyles }));

    return {
      computedStyles,
    };
  },
};
