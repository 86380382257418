
















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ManufacturerLink',
  props: {
    to: {
      type: String,
      default: '/',
    },
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
  },
});
