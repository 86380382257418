























































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';

import { MANUFACTURERS } from './config';
import ManufacturerLink from './Link.vue';

const VISIBLE_ITEMS = 12;

export default defineComponent({
  name: 'ManufacturerPreloaded',
  components: {
    SvgImage,
    ManufacturerLink,
    ManufacturerHomeLink: () => import('./HomeLink.vue'),
  },
  props: {
    manufacturerType: {
      type: String,
      default: 'ink',
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const showAll = ref<boolean>(false);
    const items = computed(() => MANUFACTURERS[props.manufacturerType]);

    return {
      showAll,
      items,
      VISIBLE_ITEMS,
    };
  },
});
