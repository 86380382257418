






import { computed, defineComponent } from '@nuxtjs/composition-api';

import HTMLContent from '~/components/common/HTMLContent.vue';
import { useCmsBlocksStore } from '~/stores/cmsBlocks';

export default defineComponent({
  name: 'HomePromo',
  components: {
    HTMLContent,
  },
  setup() {
    const { getCmsBlockByIdentifier } = useCmsBlocksStore();
    const aboutContent = computed(() => getCmsBlockByIdentifier('home_about_cartridgesave'));

    return {
      aboutContent,
    };
  },
});
