






import { computed, defineComponent } from '@nuxtjs/composition-api';

import HTMLContent from '~/components/common/HTMLContent.vue';
import { useCmsBlocksStore } from '~/stores/cmsBlocks';

export default defineComponent({
  name: 'HomeAdvantages',
  components: {
    HTMLContent,
  },
  setup() {
    const { getCmsBlockByIdentifier } = useCmsBlocksStore();
    const advantagesContent = computed(() => getCmsBlockByIdentifier('home_usp_2'));

    return {
      advantagesContent,
    };
  },
});
